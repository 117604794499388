import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { FocusScope } from "react-aria";
import { useTypedSelector } from "../../redux/hooks";
import { selectActivePage, selectProvisioningOpen, selectShowFeedback } from '../../redux/slices';
import {
    ChatErrorBoundary,
    ChatSidebar,
    ErrorBoundary,
    Navbar,
    NotificationCentre,
    TabNotifications
} from '../../components';
import { Phone } from '../phone';
import styles from './Home.module.scss';
import CallFeedbackContainer from "../../components/forms/CallFeedback/CallFeedbackContainer";
import KeypadProvider from "../../context/KeypadContext/context";
import Keypad from "../../components/keypad";
import NoChat from "../auth/NoChat";
import { Chat } from "../chat";
import { Settings } from "../settings";
import VideoSideBar from "../../components/sidebars/Video";
import { ToastCenter } from "../../components/toastCenter";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import { getFocusableElements, getFocusableInputFields } from "../../helpers";
import SettingsList from "../../components/sidebars/settings/SettingsList";
import { HotKeyNavValue } from "../../context/HotKeysContext/context";
import Aside from "../../components/aside";
import HomeReRenderOffLoad from "./children/HomeReRenderOffLoad";
import ContactSidebar from "../../components/sidebars/Contact/ContactSidebar";
import UserStatusController from "./children/UserStatusController";
import ContactViewerWrapper from "../../modals/ContactViewer";
import PhonebookContactEditorModal from "../../modals/PhonebookContactEditorModal";
import SmartDaillerErrorModal from "../../modals/SmartDiallerError";
import HomeContexProviders from "./children/HomeContexProviders";
import Video from "../video";
import CallMidpoint from "../../components/forms/CallMidpoint";


const ProvisioningContainer = lazy(() => import('../../components/Provisioning/ProvisioningContainer'))
const Design = lazy(() => import('../Design'))

export const isElectron = navigator.userAgent.toLowerCase().includes(' electron/');

interface HomeProps {
    setIsLoaded: (val: boolean) => void;
}

export const Home: React.FC<HomeProps> = ({ setIsLoaded }) => {
    const user = useTypedSelector(state => state.user);

    const showProvisioning = useTypedSelector(selectProvisioningOpen);

    // CHAT Related
    const largeFontMode = useTypedSelector(state => state.user.settings.chat.settings?.isLargeFont);

    // SIP Related
    // const showShortcodePage = useTypedSelector(state => state.sip.showShortcodePage);
    const showFeedbackForm = useTypedSelector(selectShowFeedback);
    const canCollectCallFeedback = useTypedSelector(state => state.sip.callFeedback.enabled);
    const page = useTypedSelector(selectActivePage);

    // const [contactViewer, setContactViewer] = useState<any>();

    const atKeypadRef = useRef<HTMLDivElement>(null);
    const atNavBarRef = useRef<HTMLElement>(null);
    const atSidebarRef = useRef<HTMLElement>(null);
    const atPageRef = useRef<HTMLElement>(null);

    let activePage: React.ReactElement | null;
    let activeSidebar: React.ReactElement | null;
    let sectionId;
    let fallback: React.ReactElement | null = null;

    // clear the path state if logged in with azure

    const useFocus = (ref: HTMLElement) => {
        const focusElement = getFocusableInputFields({ container: ref })[0] || getFocusableElements({ container: ref })?.[0]
        if (focusElement) {
            (focusElement as HTMLElement).focus()
        }
    }

    const hotKeyNavigate = (focusTo: HotKeyNavValue) => {
        switch (focusTo) {
            case 'nav':
                if (atNavBarRef.current) {
                    const focusElement = getFocusableElements({ container: atNavBarRef.current })?.[0]
                    if (focusElement) {
                        (focusElement as HTMLElement).focus()
                    }
                }
                break;
            case 'sidebar':
                if (atSidebarRef.current) {
                    useFocus(atSidebarRef.current)
                }
                break;
            case 'main':
                if (atPageRef.current) {
                    useFocus(atPageRef.current)
                }
                break;
            case 'keypad':
                if (atKeypadRef.current) {
                    const focusElement = getFocusableElements({ container: atKeypadRef.current })?.[0]
                    if (focusElement) {
                        (focusElement as HTMLElement).focus()
                    }
                }
        }
    }

    useEffect(() => {
        const hotKey = (e) => {
            if (e.code === 'Digit1' && e.shiftKey && e.altKey) {
                e.preventDefault()
                hotKeyNavigate('nav')
            }
            if (e.code === 'Digit2' && e.shiftKey && e.altKey) {
                e.preventDefault()
                hotKeyNavigate('sidebar')
            }
            if (e.code === 'Digit3' && e.shiftKey && e.altKey) {
                e.preventDefault()
                hotKeyNavigate('main')
            }
            if (e.code === 'Digit4' && e.shiftKey && e.altKey) {
                e.preventDefault()
                hotKeyNavigate('keypad')
            }
        }

        window.addEventListener('keydown', hotKey)

        return () => {
            window.removeEventListener('keydown', hotKey)
        };
    }, []);

    switch (page) {
        case 'chat':
            sectionId = 'chat';

            if (user.chat_enabled) {
                activeSidebar =
                    <ChatSidebar />;
                activePage = (
                    <ErrorBoundary errorComponent={<ChatErrorBoundary />}>
                        <Chat />
                    </ErrorBoundary>
                );
            } else {
                activeSidebar = null;
                activePage = <NoChat />;
            }
            break;

        case 'video':
            sectionId = 'meeting';
            activePage = <Video />;
            activeSidebar = <VideoSideBar />
            fallback = (
                <div className='video-page'>
                    :/
                </div>
            )
            break;

        case 'settings':
            sectionId = 'settings';
            activeSidebar = <SettingsList />
            activePage = <Settings />;
            break;

        case 'design':
            sectionId = 'design'
            activeSidebar = null;
            activePage = <Design />
            break;

        case 'phone':
        default:
            activeSidebar = (
                <ContactSidebar />
            );
            sectionId = 'phone';
            activePage = (
                <Phone />
            );
    }

    return (
        <HomeContexProviders hotKeyNavigate={hotKeyNavigate}>
            <UserStatusController />
            <HomeReRenderOffLoad
                setIsLoaded={setIsLoaded}
            />
            <div
                className={styles.container}
                data-is-browser={!isElectron || null}
            >
                <Suspense>
                    {showProvisioning ? (
                        <ProvisioningContainer />
                    ) : null}
                </Suspense>
                <KeypadProvider>
                    <Keypad ref={atKeypadRef} />
                </KeypadProvider>
                <FocusScope>
                    <Navbar
                        ref={atNavBarRef}
                    />
                </FocusScope>
                <div>
                    <main
                        id='main_screen'
                        className={`${page === 'video' ? 'hidden-header ' : ''}${!largeFontMode ? 'small-font' : ''
                            }`}
                    >
                        <Suspense fallback={<LoadingSpinner color='mamba' />}>
                            {activeSidebar ? (
                                <Aside
                                    activeSidebar={activeSidebar}
                                    ref={atSidebarRef}
                                />
                            ) : null}
                        </Suspense>
                        <Suspense
                            fallback={fallback}
                        >
                            <section
                                id={sectionId}
                                ref={atPageRef}
                            >
                                {activePage}
                            </section>
                        </Suspense>
                        {(canCollectCallFeedback && showFeedbackForm) &&
                            <CallFeedbackContainer />
                        }
                        <SmartDaillerErrorModal />
                        <PhonebookContactEditorModal />
                        <ContactViewerWrapper />
                        <NotificationCentre />
                        <ToastCenter />
                        <TabNotifications />
                        <CallMidpoint />
                    </main>
                </div>
            </div>
        </HomeContexProviders>
    );
};

export default Home;
