/* eslint-disable consistent-return */
import {BaseQueryFn, createApi} from '@reduxjs/toolkit/query/react';
import axios, {AxiosRequestConfig, AxiosError} from 'axios';
import {errorHandler} from "./errorHandling";

const axiosBaseQuery =
    ({baseUrl}: { baseUrl: string }): BaseQueryFn<AxiosRequestConfig, unknown, { status?: number; data?: any }> =>
        async requestConfig => {
            try {
                const result = await axios({
                    ...requestConfig,
                    url: baseUrl + requestConfig.url,
                });

                return {data: result.data};
            } catch (axiosError) {
                const err = axiosError as AxiosError;

                errorHandler({
                    requestConfig,
                    err
                })

                return {
                    error: {status: err.response?.status, data: err.response?.data}
                };
            }
        };

// const base = window.location.pathname.split('/')[1] === 'x' ? '/x' : '';

export const api = createApi({
    reducerPath: 'api',
    baseQuery: axiosBaseQuery({baseUrl: ''}),
    endpoints: () => ({}),
    tagTypes: ['Meetings','hardware_Config', 'chat_list'],
});

export const {resetApiState} = api.util;

export default api;