import moment, { Moment } from 'moment';
import { api } from '.';
import { YayWebSocket } from '../web-socket/webSocket';
import {
    checkCallFetcher, getSmartDiallerDevices,
    selectCurrentUser,
    selectSmartDialLocations,
    setUserStatuses,
    updateSmartDialLocations,
    updateUserStatus
} from '../slices';
import { IParkingSlot, IUserStatus } from '../../types';
import { AppDispatch, AppThunk, RootState } from '../store';
import { UserAuth } from './chatApi';

const url = 'wss://bifrost.yay.com/';
const WEBSOCKET: YayWebSocket = new YayWebSocket(url, []);

let AUTH_TOKEN: string | undefined;

let TIME_REF: Moment | undefined;

export const invalidateStatusesSocket = () => {
    AUTH_TOKEN = undefined;
};

export const connectStatusesSocket = () => {
    WEBSOCKET?.connect();
};

type KillReason = 'switch-user' | 'logout' | 'error' | 'offline' | 'reset_user';

export const killStatusesSocket = (reason: KillReason) => {
    WEBSOCKET?.close(4001, reason);
    invalidateStatusesSocket();
};

export const checkStatusesSocketConnection = () => WEBSOCKET.getReadyState();

const generateAuthToken = (userAuth: UserAuth): Promise<{ token: string }> =>
    new Promise((resolve, reject) => {
        WEBSOCKET.call('authenticate', userAuth)
            .then(({ result: { token } }) => {
                resolve({ token });
            })
            .catch(error => reject(error));
    });

export const handleStatusSocketAuth = async ({
    credentials,
    onAuth
}: {
    credentials: UserAuth;
    onAuth: any;
}) => {
    try {
        const { token } = await generateAuthToken(credentials);
        AUTH_TOKEN = token;
        onAuth();
        TIME_REF = moment();
    } catch (error) {
        setTimeout(() => handleStatusSocketAuth({ credentials, onAuth }), 1500);
    }
};

export const checkTimeRef = (): boolean =>
    TIME_REF ? TIME_REF > moment().subtract(1, 'hours') : false;

export const subscribeUserStatus = async (
    sipNames: string[],
    parkingSlotNumbers: number[] = []
): Promise<{
    result: IUserStatus[];
}> => {
    const parkingSlotsToSubscribe = parkingSlotNumbers.map(slot => `${slot}@parking`);

    return WEBSOCKET.call(
        'subscribe_user_status',
        {
            auth_token: AUTH_TOKEN,
            user: sipNames.concat(parkingSlotsToSubscribe).join(',')
        },
        {
            allowTime: 20000
        }
    );
};

const createStatusesSocket = ({ getState, dispatch }) => {
    WEBSOCKET.onConnect = async () => {
        const state: RootState = getState();
        const currentUser = selectCurrentUser(state);

        // user might be null if the user was logged out
        if (!currentUser || !WEBSOCKET || !currentUser.name || !currentUser.password) return;

        const credentials = {
            username: currentUser.name.toLowerCase(),
            password: currentUser.password,
            user_status_only: true
        };

        const { parkingSlots } = state.sip; // potentially move to callsApi

        await handleStatusSocketAuth({
            credentials,
            onAuth: async () => {
                try {
                    WEBSOCKET.subscribe('notify_user_status', (data: { params: IUserStatus }) => {
                        if (data.params.sipuser === credentials.username) {
                            const { params } = data;
                            dispatch(checkCallFetcher(params));

                            const { registrations } = params;

                            if (Array.isArray(registrations)) {
                                const currentState = getState();

                                const currentLocations = selectSmartDialLocations(currentState);

                                if (registrations.length !== currentLocations.length) {
                                    dispatch(updateSmartDialLocations(registrations));
                                    dispatch(getSmartDiallerDevices(currentUser.uuid))
                                }
                            }
                        }
                        return notifyUserStatus(data, {
                            dispatch
                        });
                    });

                    // potentially move to callsApi

                    setTimeout(() => {
                        WEBSOCKET.ping();
                    }, 10000);

                    subscribeUserStatus(
                        [],
                        (parkingSlots || [])?.map((slot: IParkingSlot) => slot.slot_number)
                    )
                        .then(r => {
                            dispatch(setUserStatuses(r.result));
                        })
                        .catch(() => {
                            console.warn('Failed to get user status');
                        });
                } catch (error) {
                    // do nothing
                }
            }
        });
    };

    /*
    * This close happens when ping fails / need to reset auth
    *
    * Closing from losing internet connection is handled in
    *   the offline / online handler
    * */
    WEBSOCKET.onClose = () => {
        if (!window.navigator.onLine) {
            return;
        }
        setTimeout(() => {
            if (!WEBSOCKET) return;
            WEBSOCKET.connect();
        }, 1500);
    }
};

export const statusApi = api.injectEndpoints({
    endpoints: builder => ({
        subscribeUserStatuses: builder.query<unknown, null>({
            queryFn: () => ({ data: [] }),
            async onCacheEntryAdded(_, { dispatch, getState }) {
                createStatusesSocket({ getState, dispatch });
                connectStatusesSocket();
            }
        })
    })
});

export const recreateStatusSocket = (): AppThunk => (dispatch: AppDispatch, getState) => {
    createStatusesSocket({ getState, dispatch });
    connectStatusesSocket();
};

const notifyUserStatus = ({ params }: { params: IUserStatus }, { dispatch }) => {
    dispatch(updateUserStatus(params));
};

export const { useSubscribeUserStatusesQuery } = statusApi;
