/* eslint-disable no-nested-ternary */
import React from 'react';
import { IYeOldeCall } from '../../../../types';
import CallHistoryRowDisplay, { UserCdrOptions } from './CallHistoryRowDisplay';
import { useCallHistoryBase } from "./hooks";
import { useTypedSelector } from "../../../../redux/hooks";
import {
    selectMailboxFromExt,
    selectUserEntityByExtension,
} from '../../../../redux/slices';
import { usePolyglot } from "../../../../context/Polyglot";

interface CallHistoryRowProps {
    call: IYeOldeCall;
}

const InternalCallHistoryRow: React.FC<CallHistoryRowProps> = ({ call }) => {
    const {
        duration,
        callee,
        callIcon,
        date,
    } = useCallHistoryBase(call);

    const userEntity = useTypedSelector(state => selectUserEntityByExtension(state, callee));
    const mailbox = useTypedSelector(state => selectMailboxFromExt(state, callee));

    const row_name = mailbox?.name || userEntity?.name;

    const { t } = usePolyglot();

    return (
        <CallHistoryRowDisplay
            answered={call.answered}
            callIcon={mailbox ? 'mail' : callIcon}
            rowName={row_name || callee}
            secondaryString={row_name && callee}
            subHeader={mailbox ? "Mailbox" : t("adjective.call_internal")}
            date={date}
            duration={duration}
            options={<UserCdrOptions userEntity={userEntity} callee={callee} />}
            elsewhere={!!mailbox}
        />
    );
};

export default InternalCallHistoryRow;
