/* eslint-disable no-param-reassign */
import React, {
    MutableRefObject,
    useState,
    useEffect,
    useRef,
    useMemo,
    DependencyList,
    useCallback,
} from 'react';
import debounce from "lodash.debounce";
import { useTypedDispatch, useTypedSelector } from "../redux/hooks";
import { useGetLoginWithTokenQuery, useGetUserQuery, usePutUserGlobalDndMutation } from "../redux/services/authApi";
import {
    isValidContactEntity,
    logout,
    parseNumber,
    selectAllCallerIds,
    selectApiOnlyPhonebooks,
    selectAppDisableDnd,
    selectCurrentUserId,
    selectDnd,
    selectLoggedInUser,
    selectMailboxFromExt,
    selectPhonebookEntityInArr,
    selectSelectableUsers,
    selectShortCodes,
    selectUserCountryCode, selectUserEntityByExtension,
    updatePhoneSettings
} from '../redux/slices';
import { getCookie } from "../pages/auth";
import { usePolyglot } from "../context/Polyglot";
import { IChat, IMailboxMenu, IShortcode, isIntegration, PhonebookContactEntity, UserEntity } from '../types';
import { getNumbersArr } from "../pages/phone/call-history/CallHistoryRow/ExternalCallHistoryRow";
import { useContactNamesContext } from "../pages/phone/GetContactNamesContext";
import { useAppContext } from '../context/AppContext/AppContext';

interface IDevices {
    audioInput: MediaDeviceInfo[] | undefined;
    audioOutput: MediaDeviceInfo[] | undefined;
    videoInput: MediaDeviceInfo[] | undefined;
}

export interface SelectSearchableOption {
    name: string,
    value: string,
    secondaryName?: string
}

export type PermissionState = 'granted' | 'denied' | 'prompt';

export const usePrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const useSPrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T>(value);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const useHasChanged = (val: any): boolean => {
    const prevVal = usePrevious(val);
    return prevVal !== val;
};

export const useOnClickOutside = (
    ref: MutableRefObject<HTMLElement | null> | MutableRefObject<HTMLElement | null>[],
    handler: (event: MouseEvent) => void,
    options?: {
        ignoreRefs?: MutableRefObject<HTMLElement | null>[];
    }
) => {
    useEffect(
        () => {
            const listener = (event: MouseEvent) => {
                // Do nothing if clicking ref's element or descendent elements
                if (
                    [...(Array.isArray(ref) ? ref : [ref]), ...(options?.ignoreRefs || [])].every(
                        reference =>
                            !reference.current || reference.current.contains(event.target as Node)
                    )
                ) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
            };
        },
        /**
         * Add ref and handler to effect dependencies
         * It's worth noting that because passed in handler is a new
         * function on every render that will cause this effect
         * callback/cleanup to run every render. It's not a big deal
         * but to optimize you can wrap handler in useCallback before
         * passing it into this hook.
         */
        [ref, options, handler]
    );
};

export const useNavigatorPermissions = (
    name: PermissionName,
    configuration?: any
): {
    status: string;
    error: boolean;
} => {
    const [error, setError] = useState(false);
    const [permitted, setPermitted] = useState('');

    useEffect(() => {
        if (window && window.navigator && window.navigator.permissions) {
            window.navigator.permissions
                .query({ name, ...configuration })
                .then(status => {
                    setPermitted(status.state);

                    status.onchange = () => {
                        setPermitted(status.state);
                    };
                })
                .catch(() => {
                    setError(true);
                });
        } else {
            setError(true);
        }
    }, [name, configuration]);

    return { status: permitted, error };
};

const getOnlineStatus = () =>
    typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
        ? navigator.onLine
        : true;

export const useOnlineStatus = () => {
    const [onlineStatus, setOnlineStatus] = useState(getOnlineStatus());

    useEffect(() => {
        const goOnline = () => setOnlineStatus(true);
        const goOffline = () => setOnlineStatus(false);

        window.addEventListener('online', goOnline);
        window.addEventListener('offline', goOffline);

        return () => {
            window.removeEventListener('online', goOnline);
            window.removeEventListener('offline', goOffline);
        };
    }, []);

    return onlineStatus;
};

export const useDebounce = <T>(value: T, delay: number) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
};

export const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<{
        width?: number;
        height?: number;
    }>({
        width: undefined,
        height: undefined
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
};


export const useHeightObserver = (ref: React.RefObject<HTMLDivElement> | undefined) => {

    const [listHeight, setListHeight] = useState<number>(0);

    const observer = useRef(
        new ResizeObserver(entries => {
            if (entries[0].contentRect.height > listHeight) {
                setListHeight(entries[0].contentRect.height)
            }
        })
    )
    useEffect(() => {
        if (ref?.current) {
            observer.current.observe(ref.current)
        }

        return () => {
            if (ref?.current) {
                observer.current.unobserve(ref.current)
            }
        }
    }, [ref, observer, listHeight])

    return { listHeight };

}

export const useOnScreen = (ref: React.RefObject<HTMLElement>) => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current);
        }

        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect();
        };
    }, [ref.current]);

    return isIntersecting;
};

export const useInterval = (callback: any, delay: number) => {
    const savedCallback = useRef<any>();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        const tick = () => {
            if (savedCallback.current) {
                savedCallback.current();
            }
        };

        const id = setInterval(tick, delay);

        return () => clearInterval(id);
    }, [delay]);
};

export const useTimeout = (callback: () => void, delay: number | undefined, hold?: boolean) => {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        if (hold || !delay) {
            return () => clearTimeout(id);
        }

        const tick = () => {
            if (savedCallback.current) {
                savedCallback.current();
            }
        };

        const id = setTimeout(tick, delay);

        return () => clearTimeout(id);
    }, [delay, hold]);
};

export const useBrowser = () => {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;

    const isChromium = !!(window as any)?.chrome;

    return { isSafari, isFirefox, isChromium };
}

export const useMediaDevices = () => {
    const [available_devices, setAvailable_devices] = useState<IDevices>({
        audioInput: undefined,
        audioOutput: undefined,
        videoInput: undefined
    });
    const [permissions_state, setPermissions_state] = useState<PermissionState | undefined>(undefined);
    const [permissions_error, setPermissions_error] = useState<boolean>(false);
    const { isFirefox, isSafari, isChromium } = useBrowser();

    const { t } = usePolyglot();

    const videoInputOptions = useMemo(() => {
        let options: SelectSearchableOption[];

        switch (true) {
            case (permissions_state && permissions_state !== 'granted') || permissions_error:
                options = [{ value: 'no-permission', name: t("phrases.camera_blocked") }]
                break;

            case available_devices.videoInput && available_devices.videoInput.length === 0:
                options = [{ value: 'no-devices', name: t("phrases.camera_not_found") }]
                break;

            default:
                options = available_devices.videoInput?.map((device: any) => ({
                    value: device.deviceId,
                    name: device.label
                })) || [];
        }

        return options;
    }, [available_devices.videoInput, permissions_state, permissions_error])

    const audioInputOptions = useMemo(() => {
        let options: SelectSearchableOption[];

        switch (true) {
            case (permissions_state && permissions_state !== 'granted') || permissions_error:
                options = [{ value: 'no-permission', name: t("phrases.mic_blocked") }]
                break;

            case available_devices.audioInput && available_devices.audioInput.length === 0:
                options = [{ value: 'no-devices', name: t("phrases.mic_not_found") }]
                break;

            default:
                options = available_devices.audioInput?.map((device: any) => ({
                    value: device.deviceId,
                    name: device.label
                })) || [];
        }

        return options;
    }, [available_devices.audioInput, permissions_state, permissions_error])

    const audioOutputOptions = useMemo(() => {
        let options: SelectSearchableOption[];

        switch (true) {
            case isSafari:
            case isFirefox:
            case (permissions_state && permissions_state !== 'granted') || permissions_error:
                options = [{ value: 'default-system', name: t("phrases.speaker_system_default") }]
                break;

            case available_devices.audioOutput && available_devices.audioOutput.length === 0:
                options = [{ value: 'no-devices', name: t("phrases.speaker_not_found") }]
                break;

            default:
                options = available_devices.audioOutput?.map((device: any) => ({
                    value: device.deviceId,
                    name: device.label
                })) || [];
        }

        return options;
    }, [available_devices.audioOutput, permissions_state, permissions_error])

    const getDefaultDeviceIdByType = (deviceType: string): string | undefined => {
        let defaultDeviceId: string | undefined;

        switch (deviceType) {
            case 'audioinput':
                if (available_devices.audioInput) {
                    defaultDeviceId =
                        available_devices.audioInput.find((device: any) => device.deviceId === 'default')?.deviceId ||
                        available_devices.audioInput[0]?.deviceId
                }
                break;
            case 'audiooutput':
                if (isChromium && available_devices.audioOutput) {
                    defaultDeviceId = available_devices.audioOutput.find((device: any) => device.deviceId === 'default')?.deviceId
                }
                break;
            case 'videoinput':
                if (available_devices.videoInput) {
                    defaultDeviceId =
                        available_devices.videoInput.find((device: any) => device.deviceId === 'default')?.deviceId ||
                        available_devices.videoInput[0]?.deviceId;
                }
                break;
        }

        return defaultDeviceId;
    }

    const handleMediaDevices = () => {
        navigator.mediaDevices.enumerateDevices()
            .then(mediaDeviceInfo => {
                const availableDevices = mediaDeviceInfo.filter((device: any) => device.label);

                const mediaDevices = availableDevices.reduce(
                    (mediaDevicesInfo: IDevices, device) => {
                        switch (device.kind) {
                            case 'audioinput':
                                return {
                                    ...mediaDevicesInfo,
                                    audioInput: [...(mediaDevicesInfo.audioInput || []), device]
                                };

                            case 'audiooutput':
                                return {
                                    ...mediaDevicesInfo,
                                    audioOutput: [...(mediaDevicesInfo.audioOutput || []), device]
                                };

                            case 'videoinput':
                                return {
                                    ...mediaDevicesInfo,
                                    videoInput: [...(mediaDevicesInfo.videoInput || []), device]
                                };

                            default:
                                return mediaDevicesInfo;
                        }
                    },
                    { audioInput: [], audioOutput: [], videoInput: [] }
                );

                setAvailable_devices(mediaDevices)
            })
            .catch(() => {
                setAvailable_devices({ audioInput: [], audioOutput: [], videoInput: [] })
            });
    };

    return {
        available_devices,
        permissions_state,
        setPermissions_state,
        permissions_error,
        setPermissions_error,
        audioInputOptions,
        audioOutputOptions,
        videoInputOptions,
        handleMediaDevices,
        getDefaultDeviceIdByType,
    };
}

export const useGetUser = () => {
    const loggedInUser = useTypedSelector(selectLoggedInUser);
    const selectableUsers = useTypedSelector(selectSelectableUsers) || [];
    const { siteVersion } = useAppContext();

    let getId: string;
    const localAppSettings =
        localStorage?.['yay.app.settings'] && JSON.parse(localStorage['yay.app.settings']);

    switch (true) {
        case (!!getCookie('token') && !loggedInUser && !!localAppSettings):
            getId = localAppSettings.defaultSipUserUuid
            break;
        case (selectableUsers.length <= 0 && !!loggedInUser?.user_details?.uuid):
            getId = loggedInUser.user_details.uuid;
            break
        case selectableUsers.length === 1:
            getId = selectableUsers[0].uuid;
            break;
        default:
            getId = ''
    }

    const dispatch = useTypedDispatch();

    const { isLoading: userIsLoading, isError: isGetUserError } = useGetUserQuery(
        {
            voip_user_uuid: getId,
            site_version: siteVersion
        },
        {
            skip: !getId
        }
    );

    const {
        // isLoading,
        isError: isTokenLoginError
    } = useGetLoginWithTokenQuery(null, {
        skip: !getCookie('token'),
        refetchOnMountOrArgChange: false,
    })

    useEffect(() => {
        if (isGetUserError) {
            dispatch(logout());
        }
        if (isTokenLoginError) {
            dispatch(logout());
        }
    }, [isGetUserError, isTokenLoginError]);

    return {
        userIsLoading,
    }
}

export interface DebounceOptions {
    leading?: boolean | undefined
    maxWait?: number | undefined
    trailing?: boolean | undefined
}

const defaultOptions: DebounceOptions = {
    leading: false,
    trailing: true
}

export type GenericFunction = (...args: any[]) => any

/**
 * Accepts a function and returns a new debounced yet memoized version of that same function that delays
 * its invoking by the defined time.
 * If time is not defined, its default value will be 250ms.
 */
export const useDebouncedCallback = <TCallback extends GenericFunction>
    (fn: TCallback, dependencies?: DependencyList, wait = 600, options: DebounceOptions = defaultOptions) => {
    const debounced = useRef(debounce<TCallback>(fn, wait, options))

    useEffect(() => {
        debounced.current = debounce(fn, wait, options)
    }, [fn, wait, options])

    useEffect(() => {
        debounced.current?.cancel()
    }, []);

    return useCallback(debounced.current, dependencies ?? [])
}

type UseCallerDisplayNameGenericResponse =
    (
        {
            type: undefined;
            data: undefined;
        } |
        {
            type: 'user';
            data: UserEntity;
        } |
        {
            type: 'contact';
            data: PhonebookContactEntity;
        } |
        {
            type: 'callerId';
            data: undefined;
        } |
        {
            type: 'short_code';
            data: undefined;
        } |
        {
            type: 'unknown';
            data: undefined;
        } |
        {
            type: 'mailbox';
            data: IMailboxMenu;
        }
    )
    &
    {
        name: string
    };

export const useCallerDisplayNameGeneric = (callerNumber: string | undefined): UseCallerDisplayNameGenericResponse => {
    const countryCode = useTypedSelector(selectUserCountryCode);
    const callerIds = useTypedSelector(selectAllCallerIds);
    const shortcodes = useTypedSelector(selectShortCodes);

    const { t } = usePolyglot();

    const entity = useTypedSelector(state => selectPhonebookEntityInArr(state, getNumbersArr(callerNumber || '', countryCode)));
    const userEntity = useTypedSelector(state => selectUserEntityByExtension(state, callerNumber));

    const mailboxMenu = useTypedSelector(state => selectMailboxFromExt(state, callerNumber || ''));

    if (mailboxMenu) {
        return {
            type: 'mailbox',
            name: mailboxMenu.name,
            data: mailboxMenu
        }
    }

    if (!callerNumber) {
        return {
            type: 'unknown',
            data: undefined,
            name: t('adjective.callee_unknown'),
        }
    }

    if (entity && isValidContactEntity(entity)) {
        return {
            type: 'contact',
            data: entity,
            name: entity.name || callerNumber,
        }
    }

    if (userEntity) {
        return {
            type: 'user',
            data: userEntity,
            name: userEntity.name,
        }
    }

    const foundCallerId = callerIds?.find(cid => cid.cli_display === callerNumber);

    if (foundCallerId) {
        return {
            type: 'callerId',
            data: undefined,
            name: foundCallerId.cli_name,
        }
    }

    const foundShortcode = shortcodes?.find(c => c.short_code === callerNumber.substring(0, 3))

    if (foundShortcode) {
        return {
            type: 'short_code',
            data: undefined,
            name: callerNumber,
        }
    }

    return {
        type: undefined,
        data: undefined,
        name: callerNumber,
    }
}


export const useShortcodeList = (searchTerm?: string) => {
    const shortCodes = useTypedSelector(selectShortCodes)

    const displayShortcodes: IShortcode[] = useMemo(() => {
        if (!shortCodes) return [];

        let res = searchTerm ? [...shortCodes].filter(s => (
            s.short_code.toLowerCase().includes(searchTerm.toLowerCase())
            || s.feature.feature.toLowerCase().includes(searchTerm.toLowerCase())
        )) : [...shortCodes];

        res = res.sort((a, b) => {
            const lenCompare = a.short_code.length - b.short_code.length;
            if (lenCompare === 0) {
                return a.short_code.toLowerCase().localeCompare(b.short_code.toLowerCase())

            }
            return lenCompare;
        })

        return res;

    }, [searchTerm, shortCodes]);

    return {
        displayShortcodes
    }
}

export const useDnd = () => {
    const dndDisabled = useTypedSelector(selectAppDisableDnd);
    const userUuid = useTypedSelector(selectCurrentUserId);

    const {
        localDnd,
        globalDnd,
        dnd
    } = useTypedSelector(selectDnd);

    const [putGlobalDnd, { isLoading: globalDndLoading }] = usePutUserGlobalDndMutation();

    const dispatch = useTypedDispatch();

    const updateDnd = (newDnd: boolean, isGlobal: boolean) => {
        if (isGlobal) {
            if (localDnd && newDnd) {
                dispatch(
                    updatePhoneSettings({
                        setting: 'dnd',
                        value: false
                    })
                )
            }

            putGlobalDnd({
                uuid: userUuid,
                dnd: newDnd,
            })

            return;
        }

        dispatch(
            updatePhoneSettings({
                setting: 'dnd',
                value: newDnd
            })
        )

        if (newDnd && globalDnd) {
            putGlobalDnd({
                uuid: userUuid,
                dnd: false,
            })
        }

    }

    return {
        globalDndLoading,
        dndDisabled,
        globalDnd,
        localDnd,
        dnd,
        updateDnd,
    };
}

const naiveRegex = /^[0-9()+_]+$/gm;

export const useChatName = (chat: IChat, parentChat: IChat | undefined): string => {
    const { t } = usePolyglot();

    const countryCode = useTypedSelector(selectUserCountryCode);
    const apiPhonebooks = useTypedSelector(selectApiOnlyPhonebooks);

    const {
        name,
        searchNumber
    }: {
        name: string,
        searchNumber: string | undefined
    } = useMemo(() => {

        const queryChat = parentChat || chat;

        if (isIntegration(queryChat)) {
            if (
                !queryChat.integration?.number
                || chat.display_name !== chat.integration?.number
                || !naiveRegex.test(chat.integration?.number || "")
            ) {
                return {
                    name: queryChat.display_name,
                    searchNumber: undefined
                };
            }

            return {
                name: chat.display_name,
                searchNumber: chat.display_name
            }
        }

        return {
            name: parentChat?.display_name || chat.display_name || t("adjective.user_unknown"),
            searchNumber: undefined
        }
    }, []);


    const contactEntity = useTypedSelector(state => selectPhonebookEntityInArr(state, getNumbersArr(searchNumber, countryCode)));

    const { addNumberForReq } = useContactNamesContext();

    useEffect(() => {
        if (!contactEntity && searchNumber && apiPhonebooks.length > 0) {
            addNumberForReq(parseNumber(searchNumber))
        }
    }, [apiPhonebooks.length]);


    if (contactEntity) {
        return contactEntity.name || contactEntity.number;
    }

    return name;
}
