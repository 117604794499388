import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    FocusMessageAttachment,
    IChat,
    IChatMessage,
    IChatState,
    INotifyMessageStatus,
    IUserForChannel
} from "../../../types";
import { authApi } from "../../services/authApi";

export const chatsAdapter = createEntityAdapter<IChat>({
    selectId: (chat) => chat.uuid,
})

const initialState: IChatState = {
    userUuid: '',
    chats: chatsAdapter.getInitialState(),
    typingStatuses: {},
    failedMessagesQueue: {},
    messageReplies: {},
    externalReplies: [],
    messageReactions: {},
    groupCreationActive: false,
    usersForGroupSelection: [],
    lastUpdatedChatId: '',
    focusAttachment: undefined
};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        addOneChat: (state, { payload }: PayloadAction<IChat>) => {
            chatsAdapter.setOne(state.chats, payload)
        },
        updateOneChat: (state, { payload }: PayloadAction<{ chat_uuid: string, changes: Partial<IChat> }>) => {
            chatsAdapter.updateOne(state.chats, {
                id: payload.chat_uuid,
                changes: { ...payload.changes }
            })
        },
        deleteOneChat: (state, { payload }: PayloadAction<string>) => {
            chatsAdapter.removeOne(state.chats, payload);
        },
        addManyChats: (state, { payload }: PayloadAction<IChat[]>) => {
            chatsAdapter.setMany(state.chats, payload)
        },
        addUserUuid: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            userUuid: payload
        }),
        setActiveChat: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            activeChat: payload
        }),
        clearActiveChat: state => ({
            ...state,
            activeChat: ''
        }),
        addFailedMessageToQueue: (state, { payload }: PayloadAction<{
            chat_uuid: string,
            message: IChatMessage
        }>) => ({
            ...state,
            failedMessagesQueue: {
                ...state.failedMessagesQueue,
                [payload.chat_uuid]: [...(state.failedMessagesQueue?.[payload.chat_uuid] || []), payload.message]
            }
        }),
        clearFailedMessageFromQueue: (state, { payload }: PayloadAction<{
            chat_uuid: string,
            message_uuid: string
        }>) => ({
            ...state,
            failedMessagesQueue: {
                ...state.failedMessagesQueue,
                [payload.chat_uuid]: state.failedMessagesQueue[payload.chat_uuid]?.filter(message => message.uuid !== payload.message_uuid)
            }
        }),
        updateChatTypingStatus: (state, { payload }: PayloadAction<INotifyMessageStatus>) => ({
            ...state,
            typingStatuses: {
                ...state.typingStatuses,
                ...(payload.to_type === 'user' ? {
                    [payload.user]: [payload]
                } : {
                    [payload.to]: [...(state.typingStatuses[payload.to]?.filter(status => status.user !== payload.user) || []), payload]
                })
            }
        }),
        clearMessageTypingStatus: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            typingStatuses: {
                ...state.typingStatuses,
                [payload]: []
            }
        }),
        addExternalReply: (state, { payload }: PayloadAction<IChatMessage>) => ({
            ...state,
            externalReplies: [...(state.externalReplies || []), payload]
        }),
        setActiveMessageReply: (
            state,
            { payload }: PayloadAction<{
                chat_uuid: string;
                data: { message: IChatMessage; sender: string };
            }>
        ) => ({
            ...state,
            messageReplies: {
                ...state.messageReplies,
                [payload.chat_uuid]: payload.data
            }
        }),
        clearActiveMessageReply: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            messageReplies: {
                ...state.messageReplies,
                [payload]: undefined
            }
        }),
        setScrollToMessageUuid: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            scrollToMessageId: payload
        }),
        setScrollToThreadMessageId: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            scrollToThreadMessageId: payload
        }),
        clearScrollToMessageUuid: state => ({
            ...state,
            scrollToMessageId: undefined
        }),
        clearScrollToThreadMessageId: state => ({
            ...state,
            scrollToThreadMessageId: undefined
        }),
        resetChatMessagesSearch: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            searchResults: {
                ...state.searchResults,
                [payload]: undefined
            },
        }),
        updateActiveChatInputText: (state, { payload }: PayloadAction<{ chat_uuid: string; text: any }>) => ({
            ...state,
            inProgressTextInputs: {
                ...state.inProgressTextInputs,
                [payload.chat_uuid]: payload.text
            }
        }),
        clearActiveChatInputText: (state, { payload }: PayloadAction<{ chat_uuid: string }>) => ({
            ...state,
            inProgressTextInputs: {
                ...(state.inProgressTextInputs),
                [payload.chat_uuid]: ""
            }
        }),
        setChannelCreation: (state, { payload }: PayloadAction<boolean>) => ({
            ...state,
            groupCreationActive: payload
        }),
        resetUsersForChannelSelection: (state) => ({
            ...state,
            usersForGroupSelection: []
        }),
        setManyUsersForChannelSelection: (state, { payload }: PayloadAction<IUserForChannel[]>) => ({
            ...state,
            usersForGroupSelection: payload
        }),
        setSearchResult: (state, { payload }: PayloadAction<{ messages: IChatMessage[], chat_key: string }>) => ({
            ...state,
            searchResults: {
                ...state.searchResults,
                [payload.chat_key]: payload.messages
            },
        }),
        setSingleUserForChannelSelection: (state, { payload }: PayloadAction<IUserForChannel>) => ({
            ...state,
            usersForGroupSelection: state.usersForGroupSelection.find(user => user.uuid === payload.uuid) ?
                state.usersForGroupSelection.filter(user => user.uuid !== payload.uuid) : [...state.usersForGroupSelection, payload]
        }),
        setFilePreview: (state, { payload }: PayloadAction<FocusMessageAttachment | undefined>): IChatState => ({
            ...state,
            focusAttachment: payload
        }),
        setLastUpdatedChatId: (state, { payload }: PayloadAction<string | undefined>) => ({
            ...state,
            lastUpdatedChatId: payload || '',
        }),
    },
    extraReducers: builder => {
        builder.addMatcher(authApi.endpoints.getUser.matchFulfilled, (state, { payload }) => ({
            ...state,
            userUuid: payload.user.uuid
        }));
    }
});

export const chat = chatSlice.reducer;
