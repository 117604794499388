import React, { ReactNode } from 'react';
import styles from './styles.module.scss';
import AriaText from "../../../../components/AriaComponents/AriaText";

type Props = ({
    infoText: string;
    customMark?: never;
    customDisplay?: never;
    className?: string;
} | {
    infoText?: never;
    customMark: ReactNode;
    customDisplay: ReactNode;
    className?: string;
}) & {
    keypad?: boolean
}

const SettingsInfoIcon: React.FC<Props> = ({ infoText, customMark, customDisplay, className, keypad }) => (
    <div
        className={[styles.info_item, className || ''].join(' ')}
    >
        {infoText !== undefined ? (<>
            <mark>i</mark>
            <AriaText text={infoText} />
            <div data-keypad={keypad || null}>
                <p>
                    {infoText}
                </p>
            </div>
        </>) : (<>
            {customMark}
            <div>
                {customDisplay}
            </div>
        </>)}
    </div>
);

export default SettingsInfoIcon;
