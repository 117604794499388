import React, { memo } from 'react';
import Markdown from "markdown-to-jsx";
import { ContactTag, UserTag } from "./MessageTags";
import { DepartmentTagByUuid } from "../../../components/buttons";
import ChatGenericTag from './ChatGenericTag';

interface Props {
    children?: string
    plain?: boolean
}

interface CustomAProps extends HTMLAnchorElement {
    children: any
}

export const userTagRegex = /<UserTag uuid="(.*?)" \/>/gm;
export const contactTagRegex = /<ContactTag contact={(.*?)}\/>/gm;
export const departmentTagRegex = /<DepartmentTag uuid="(.*?)" \/>/gm;
export const genericTagRegex = /\|~(.*?)~\|/gm;

const tagParse = (stringToParse: string): string => {

    const userTagParser = userTagRegex.exec(stringToParse)

    let difStr = stringToParse;

    if (userTagParser) {
        difStr = stringToParse.replaceAll(userTagParser[0], `[*%$User Tag$%*](${userTagParser[1]})`)
    }

    const contactTagParser = contactTagRegex.exec(difStr)

    if (contactTagParser) {
        difStr = difStr.replaceAll(contactTagParser[0], `[*%$Contact Tag$%*](${contactTagParser[1]})`)
    }

    const DepartmentTagParser = departmentTagRegex.exec(difStr)

    if (DepartmentTagParser) {
        difStr = difStr.replaceAll(DepartmentTagParser[0], `[*%$Department Tag$%*](${DepartmentTagParser[1]})`)
    }

    const GenericTagParser = genericTagRegex.exec(difStr)

    if (GenericTagParser) {
        difStr = difStr.replaceAll(GenericTagParser[0], `[*%$Generic Tag$%*](${GenericTagParser[1]})`)
    }

    if (!userTagParser && !contactTagParser && !DepartmentTagParser && !GenericTagParser) return stringToParse;

    return tagParse(difStr)
}

export const MarkdownContainer: React.FC<Props> = memo(({ children, plain }) => {

    const CustomA = (props: CustomAProps) => {
        if (props.children[0]?.props?.children?.[0] === '%$User Tag$%') {
            return (
                <span>
                    <UserTag
                        key={props.href}
                        tag={{ uuid: props.href }}
                        plain={plain}
                    />
                </span>
            )
        }

        if (props.children[0]?.props?.children?.[0] === '%$Department Tag$%') {
            return (
                <span>
                    <span>
                        <DepartmentTagByUuid uuid={props.href} />
                    </span>
                </span>
            )
        }

        if (props.children[0]?.props?.children?.[0] === '%$Contact Tag$%') {
            return (
                <span>
                    <ContactTag
                        tag={{ contact: props.href }}
                        plain={plain}
                    />
                </span>
            )
        }

        if (props.children[0]?.props?.children?.[0] === '%$Generic Tag$%') {
            const [text, colour] = props.href.split(':');

            return (
                <ChatGenericTag
                    text={text}
                    colour_raw={colour}
                    plain={plain}
                />
            )
        }

        if (plain) return (
            <>
                {props.children}
            </>
        );

        return (
            <a
                href={props.href}
                title={props.href}
                target='_blank'
                rel='noreferrer'
            >
                {props.children}
            </a>
        )
    }

    const HeaderOverride = (props: any) => (
        <p>
            #{props.children}
        </p>
    )

    const HideBr = () => (
        <>&nbsp;</>
    )

    const TextOnly = (props: any) => (
        <>
            {props.children}
        </>
    )

    // Mayve look to implementing language level highlighting
    const CustomCode: React.FC<{ className: string | undefined, children: string | string[] }> = (props) => props.className || Array.isArray(props.children) ? (
        <pre>
            <code>
                {props.children}
            </code>
        </pre>
    ) : (
        <code >
            {props.children}
        </code>
    )


    if (!children) return null;

    return (
        <Markdown
            options={{
                forceInline: plain || false,
                wrapper: plain ? TextOnly : undefined,
                forceWrapper: plain,
                overrides: {
                    a: {
                        component: CustomA
                    },
                    this: {
                        component: TextOnly
                    },
                    br: {
                        component: plain ? HideBr : 'br'
                    },
                    span: {
                        component: plain ? TextOnly : 'p'
                    },
                    p: {
                        component: plain ? TextOnly : 'p'
                    },
                    h1: HeaderOverride,
                    div: {
                        component: TextOnly
                    },
                    code: {
                        component: plain ? 'code' : CustomCode
                    },
                    yTag: {
                        component: 'p'
                    }
                },
                disableParsingRawHTML: true,
            }}
        >
            {tagParse(children)}
        </Markdown>
    );
})
