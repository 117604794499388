import React from "react";
import styles from "../../../../Keypad.module.scss"
import { useTypedSelector } from "../../../../../../redux/hooks";
import { useCallContext } from "../../../../../../context/CallContext/context";
import {
    selectAllCalls,
    selectMailboxFromExt,
} from "../../../../../../redux/slices";
import { TransferringSectionData } from "../../../TransferringKeypad/TransferringKeypad";

interface TransferringDisplayProps {
}

export const TransferringDisplay: React.FC<TransferringDisplayProps> = () => {
    const { call } = useCallContext();

    const mailbox = useTypedSelector(state => selectMailboxFromExt(state, call.callee || ""));
    const calls = useTypedSelector(selectAllCalls);

    const rootCall = calls.find(c => c.id === call.beingTransferredTo)

    if (mailbox) {
        return (
            <div className={styles.transferring_display}>
                <p>To transfer a call to a voicemail, please select the blind transfer option and try again.</p>
            </div>
        )
    }

    if (!rootCall) {
        return (
            <div className={styles.transferring_display}>
                <p>Transfer No Longer Available</p>
            </div>
        )
    }

    return (
        <div className={styles.transferring_display}>
            <p>Transferring</p>
            <TransferringSectionData
                callee={rootCall.callee as string}
                displayName={rootCall.displayName || ''}
            />
        </div>
    )
}

export default TransferringDisplay;
