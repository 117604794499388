/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import { combineReducers } from '@reduxjs/toolkit';
import { RootState } from './store';
import { api } from './services';
import { app, auth, chat, chatsAdapter, provision, sip, status, user, video } from './slices';
import { message } from './slices/messageSlice';

const appReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    app,
    auth,
    chat,
    message,
    provision,
    sip,
    status,
    user,
    video
});

const rootReducer = (state: RootState, action: { type: string }) => {

    if (action.type === 'auth/switch-user' || action.type === 'auth/reset_user') {
        state = {
            ...state,
            auth: {
                ...state.auth,
                loginStep: action.type === 'auth/reset_user' ? 'reset_user' : 'users'
            },
            sip: {
                calls: [],
                callHistoryLoading: 'idle',
                callHistory: [],
                callerIdsLoading: 'idle',
                callerIds: [],
                parkingSlotsLoading: 'idle',
                callHistoryFillerLoading: 'idle',
                callFeedback: {
                    show: false,
                    forms: []
                },
                usersForGroupCall: [],
                conferenceInvitations: []
            },
            status: {
                userStatuses: {},
                callWhenAvailable: []
            },
            user: undefined,
            chat: {
                userUuid: '',
                chats: chatsAdapter.getInitialState(),
                typingStatuses: {},
                failedMessagesQueue: {},
                messageReplies: {},
                externalReplies: [],
                messageReactions: {},
                groupCreationActive: false,
                usersForGroupSelection: []
            },
            message: {
                chat: {}
            },
            app: {
                ...state.app,
                follow_me_apps: undefined,
                follow_me_registration: undefined
            }
        };
    }

    switch (action.type) {
        case 'auth/switch-user':
            localStorage['yay.app.settings'] = null;
            break;
        case 'auth/reset_chat':
            state = {
                ...state,
                chat: {
                    userUuid: '',
                    chats: chatsAdapter.getInitialState(),
                    typingStatuses: {},
                    failedMessagesQueue: {},
                    messageReplies: {},
                    externalReplies: [],
                    messageReactions: {},
                    groupCreationActive: false,
                    usersForGroupSelection: []
                },
                message: {
                    chat: {}
                }
            };
            break;
        case 'auth/logout':
            localStorage['yay.app.settings'] = null;
            sessionStorage.clear();
            document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

            // @ts-ignore
            state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
